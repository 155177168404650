import { ICategoryPageResponse } from 'server/models/categoryPage';
import { IBrandsResponse } from 'models/brand.model';
import BasesService from '../basesManager.service';
import { AuthHelper } from 'utils/authHelper.util';
import { IProductDetails, IProductRelated, IProductsResponse } from 'shared/models/product.model';
import { IFilterResponse } from 'shared/models/filters.model';
import { ICatalogHasModelsParams } from 'models/services/api/catalogHasModelsParams.model';

export class PimCatalogService {
  private static getFiasId(): string | undefined {
    return inject<BasesService>(BasesService.getServiceName())?.getCurrentRegionFiasId();
  }

  public static async getBrands(): Promise<IBrandsResponse> {
    return await AuthHelper.fetch<IBrandsResponse>('/api/v1/brands', {
      method: 'GET',
    });
  }

  public static async getCategories(
    maxLevel = 6,
    fiasId?: string,
    text?: string,
    pimCategoryId?: Array<string>,
    hasMtrCode?: boolean,
    useMtrPrice?: boolean,
    useAllowedCategory?: boolean,
    clientId?: number,
    basisId?: number,
  ): Promise<ICategoryPageResponse> {
    return await AuthHelper.fetch<ICategoryPageResponse>('/api/v1/categories', {
      method: 'GET',
      params: {
        maxLevel,
        fiasId,
        text,
        pimCategoryId,
        hasMtrCode,
        useMtrPrice,
        useAllowedCategory,
        clientId,
        basisId,
      },
    }, true);
  }

  public static async getCategoryById(
    categoryId: number | string,
    maxLevel = 2,
    fiasId?: string,
    hasMtrCode?: boolean,
    useMtrPrice?: boolean,
    useAllowedCategory?: boolean,
    clientId?: number,
    basisId?: number,
    okeiCode?: string,
  ): Promise<ICategoryPageResponse> {
    return await AuthHelper.fetch<ICategoryPageResponse>(`/api/v1/categories/${categoryId}`, {
      method: 'GET',
      params: {
        fiasId,
        maxLevel,
        hasMtrCode,
        useMtrPrice,
        useAllowedCategory,
        clientId,
        basisId,
        okeiCode,
      },
    });
  }

  public static async getProducts(params: Record<string, unknown>): Promise<IProductsResponse> {
    return await AuthHelper.fetch<IProductsResponse>('/api/v1/products', {
      method: 'GET',
      params,
      cache: 'no-cache',
    });
  }

  public static async getProductDetailsById(
    productId: number,
    clientId: string | number,
    fiasId = this.getFiasId(),
    needClientItemCode?: string,
    params = {} as Record<string, unknown>,
  ): Promise<IProductDetails> {
    return await AuthHelper.fetch<IProductDetails>(`/api/v1/products/${productId}`, {
      cache: 'no-cache',
      params: { fiasId, clientId, needClientItemCode, ...params },
    });
  }

  public static async getFilters(params: Record<string, unknown>): Promise<IFilterResponse> {
    return AuthHelper.fetch<IFilterResponse>('/api/v1/filters', {
      method: 'GET',
      params,
      cache: 'no-cache',
    });
  }

  public static async getProductRelated(
    models: Array<number>,
    fiasId?: string,
    okeiCode?: string,
    clientId?: number,
    hasMtrCode?: boolean,
    useMtrPrice?: boolean,
    useAllowedCategory?: boolean,
    basisId?: number,
    filterByPimMapping?: boolean,
    directDelivery?: boolean,
    available?: boolean,
    catalogId?: Array<number>,
  ): Promise<IProductRelated> {
    return AuthHelper.fetch<IProductRelated>('/api/v1/products/related', {
      method: 'POST',
      body: { models, filterByPimMapping },
      params: {
        fiasId,
        okeiCode,
        clientId,
        hasMtrCode,
        useMtrPrice,
        useAllowedCategory,
        basisId,
        directDelivery,
        available,
        catalogId,
      },
    });
  }

  public static async isCatalogHasModels(params: ICatalogHasModelsParams): Promise<boolean> {
    return await AuthHelper.fetch('/api/v1/catalog/has_models', {
      method: 'GET',
      params,
    });
  }
}
